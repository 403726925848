<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th class="text-nowrap">PO Details</th>
          <th class="text-nowrap">PO Value</th>
          <th>Currency</th>
          <th class="text-nowrap">GRN Value</th>
          <th>Paid</th>
          <th>Due</th>
          <th class="text-nowrap" v-for="(stepType, key) in importTrackerStepTypes">{{ stepType }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in itList.data" :key="i">
          <td class="text-nowrap">
            {{ item.po_number }}<br>
            {{ item.date }}<br>
            {{ item.contact_profile?.full_name }}<br>
          </td>
          <td>{{ commaFormat(item.total_po_value) }}</td>
          <td>
            <div v-for="currency in item.currencies" class="my-25 badge bg-secondary">
              {{ currency }}
            </div>
          </td>
          <td>{{ commaFormat(item.total_grn_value) }}</td>
          <td>
            <td class="step-type">
              <CommonStepType
                  :item="item"
                  :stepTypes="item.paid_value"
                  stepKey="paid_value"
                  title="PAID Value"
                  @openEditModal="openEditModal"
                  @openModal="openModal"
              />
            </td>
          </td>
          <td>{{ getDueValue(item.total_po_value, item.paid_value) }}</td>
          <td class="step-type">
            <CommonStepType
                :item="item"
                :stepTypes="item.pi_receive"
                stepKey="pi_receive"
                title="PI Receiving"
                @openEditModal="openEditModal"
                @openModal="openModal"
            />
          </td>
          <td class="step-type">
            <CommonStepType
                :item="item"
                :stepTypes="item.product_readiness"
                stepKey="product_readiness"
                title="Product Readiness"
                @openEditModal="openEditModal"
                @openModal="openModal"
            />
          </td>
          <td class="step-type">
            <CommonStepType
                :item="item"
                :stepTypes="item.ip_status"
                stepKey="ip_status"
                title="IP Status"
                @openEditModal="openEditModal"
                @openModal="openModal"
            />
          </td>
          <td class="step-type">
            <CommonStepType
                :item="item"
                :stepTypes="item.apply_for_lc"
                stepKey="apply_for_lc"
                title="Apply for LC"
                @openEditModal="openEditModal"
                @openModal="openModal"
            />
          </td>
          <td class="step-type">
            <CommonStepType
                :item="item"
                :stepTypes="item.lc_opened"
                stepKey="lc_opened"
                title="LC Opened"
                @openEditModal="openEditModal"
                @openModal="openModal"
            />
            </td>
          <td>{{ commaFormat(item.total_lc_opened_amount) }}</td>
          <td class="step-type">
            <CommonStepType
                :item="item"
                :stepTypes="item.noc_shipping_guarantee"
                stepKey="noc_shipping_guarantee"
                title="NOC/ Shipping Guarantee"
                @openEditModal="openEditModal"
                @openModal="openModal"
            />
          </td>
          <td class="step-type">
            <CommonStepType
                :item="item"
                :stepTypes="item.product_release_status"
                stepKey="product_release_status"
                title="Product Release status"
                @openEditModal="openEditModal"
                @openModal="openModal"
            />
          </td>
          <td>{{ commaFormat(item.total_ledger_generals_amount) }}</td>
          <td>{{ item.total_grn_quantity }}</td>
          <td>
            <router-link v-if="item.landed_costing_master" role="button" class="my-25 badge bg-secondary text-white"
                         :to="{name: 'landed-costing-edit', query:{'landedCosting' : item.landed_costing_master.id}}"
                         target="_blank">
              Date: {{ item.landed_costing_master.date }}<br>Number: {{ item.landed_costing_master.bill_number }}
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!itList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
  <StepTypeModal
      :contacts="contacts"
      :modalTitle="modalTitle"
      :isEdit="isEdit"
      ref="stepTypeModalRef"
      :formData="formData"
      @onSubmit="handleSubmit"
      @onClose="clearModal"
  />
</template>

<script setup>
import {inject, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import figureFormatter from "@/services/utils/figureFormatter";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import StepTypeModal from "@/components/molecule/procurement/import-tracker/StepTypeModal.vue";
import CommonStepType from "@/components/molecule/procurement/import-tracker/CommonStepType.vue";

const props = defineProps({
  itList: Object,
});
const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const contacts = ref([])
const {commaFormat} = figureFormatter()
const modalTitle = ref('Add PI Receiving')
const isEdit = ref(false)
const stepTypeModalRef = ref(null);
const selectedPo = ref({})
const selectedStepType = ref('')
let loader = ref(false)
const {storeImportTracker, importTrackerStepTypes, updateImportTracker, fetchContacts} = handleImportTracker();
const formData = ref({
  step_type: '',
  purchase_order_master_id: '',
  date: '',
  number: '',
  short_description: '',
  full_description: '',
  company_contact_id: '',
  amount: '',
  follow_up_date: '',
  linked_reference_no: '',
  attachments: [],
})

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const openModal = (item, stepType, stepTitle = 'PI Receiving') => {
  selectedPo.value = item
  clearModal()
  modalTitle.value = `Add ${stepTitle}`
  isEdit.value = false
  selectedStepType.value = stepType
  stepTypeModalRef.value?.toggleModal()
}

const getDueValue = (total_po_value, paid_value) => {
  const total_paid_value = paid_value.reduce((sum, {amount}) => sum + Number(amount), 0)
  return commaFormat(total_po_value - total_paid_value)
}

const openEditModal = (item, stepItem, stepType, stepTitle = 'PI Receiving') => {
  clearModal()
  selectedPo.value = item
  formData.value = stepItem;
  modalTitle.value = `Edit ${stepTitle}`
  isEdit.value = true
  selectedStepType.value = stepType
  stepTypeModalRef.value?.toggleModal(true)
}
const handleSubmit = () => {
  stepTypeModalRef.value.loading = true
  formData.value.purchase_order_master_id = selectedPo.value.id
  formData.value.step_type = selectedStepType.value

  const copyFormData = new FormData();
  const {
    step_type,
    purchase_order_master_id,
    date,
    number,
    short_description,
    full_description,
    follow_up_date,
    linked_reference_no,
    company_contact_id,
    amount,
    user_id,
    attachments
  } = formData.value;

  copyFormData.append('step_type', step_type);
  copyFormData.append('purchase_order_master_id', purchase_order_master_id);
  copyFormData.append('date', date);
  copyFormData.append('number', number ? number : '');
  copyFormData.append('short_description', short_description ? short_description : '');
  copyFormData.append('full_description', full_description ? full_description : '');
  copyFormData.append('follow_up_date', follow_up_date ? follow_up_date : '');
  copyFormData.append('linked_reference_no', linked_reference_no ? linked_reference_no : '');
  copyFormData.append('company_contact_id', company_contact_id ? company_contact_id : '');
  copyFormData.append('amount', amount ? amount : '');
  copyFormData.append('user_id', user_id ? user_id : '');


  if (attachments) {
    if (isEdit.value) {
      const previousFileIds = attachments
          .filter(attachment => typeof attachment === 'object' && attachment.hasOwnProperty('id'))
          .map(attachment => attachment.id);

      attachments.forEach((attachment, index) => {
        if (typeof attachment !== 'object' || !attachment.hasOwnProperty('id')) {
          let key = `attachments[${index}]`;
          copyFormData.append(key, attachment);
        }
      });

      if (previousFileIds.length) {
        copyFormData.append('previous_file_ids', JSON.stringify(previousFileIds));
      }
    } else {
      attachments.forEach((attachment, index) => {
        let key = `attachments[${index}]`;
        copyFormData.append(key, attachment);
      });
    }
  }

  let returnRes = isEdit.value
      ? updateImportTracker(formData.value.id, copyFormData, getQuery())
      : storeImportTracker(copyFormData, getQuery())

  returnRes.then(res => {
    loader.value = false
    if (!res.status) {
      return showError(res.message)
    }

    showSuccess(res.message)
    if (!isEdit.value) {
      isEdit.value = false
      stepTypeModalRef.value.toggleModal()
      return selectedPo.value[selectedStepType.value].push(res.data)
    }

    const index = selectedPo.value[selectedStepType.value].findIndex(obj => obj.id === res.data.id);
    if (index !== -1) {
      selectedPo.value[selectedStepType.value].splice(index, 1, res.data);
    }
    isEdit.value = false
    stepTypeModalRef.value.toggleModal()
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loader.value = stepTypeModalRef.value.loading = false
  })
}
const clearModal = () => {
  formData.value = {
    step_type: '',
    purchase_order_master_id: '',
    date: '',
    number: '',
    short_description: '',
    full_description: '',
    company_contact_id: '',
    amount: '',
    follow_up_date: '',
    linked_reference_no: '',
    attachments: [],
  }
}

onMounted(async () => {
  loader.value = true
  const contactsRes = fetchContacts(getQuery());
  await Promise.all([
    contactsRes.then(res => {
      if (res.data) contacts.value = res.data;
    }),
  ]).then(() => {
    loader.value = false;
  }).catch((err) => {
    loader.value = false
  });
})
</script>

<style lang="scss">
.step-type {
  text-align: center;

  a {
    font-size: 28px;
  }
}
</style>
