<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="false"
          title="Import Tracker List"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <DateQuerySetter @onClickGo="getITList">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">PO Number</label>
          <input class="form-control" type="text" name="po_number" v-model="po_number">
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">CS/Quotation Number</label>
          <input class="form-control" type="text" name="cs_number" v-model="cs_number">
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label">Supplier</label>
          <vField v-model="supplier_id" class="d-none" name="supplier_id"/>
          <v-select
              placeholder="Select Supplier"
              v-model="supplier_id"
              :options="contacts"
              label="name"
              :reduce="name => name.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requester</label>
          <input readonly class="form-control" type="text" name="Requester" v-model="user">
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label">Projects</label>
          <vField v-model="project_id" class="d-none" name="project_id"/>
          <v-select
              placeholder="Select project"
              v-model="project_id"
              :options="projects"
              label="name"
              :reduce="name => name.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Status</label>
          <vField v-model="status" class="d-none" name="status"/>
          <v-select
              placeholder="Select Status"
              v-model="status"
              :options="allStatus"
              label="name"
              :reduce="name => name.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
          <label for="colFormLabel" class="col-form-label">Quick Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
      </DateQuerySetter>
    </div>

    <ListTable :it-list="ipList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
  </div>
  <GlobalLoader/>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/import-tracker/ITListingTable.vue'
import Pagination from '@/components/atom/Pagination'
import {
  inject,
  ref,
  onMounted,
  computed,
  watch
} from 'vue';
import {
  useRoute,
  useRouter
} from "vue-router";
import handleContact from '@/services/modules/contact'
import handleProjects from "@/services/modules/procurement/project";
import {useStore} from "vuex";
import handleImportTracker from "@/services/modules/procurement/importTracker";

const router = useRouter()
const route = useRoute()
const loading = ref(false);
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchContactProfiles} = handleContact()
const {fetchImportTracker} = handleImportTracker();
const {fetchProjects} = handleProjects();

let ipList = ref({});
let companyId = computed(() => route.params.companyId);
const user = computed(() => {
  return store.state.user.user.name;
})
const offset = ref(5);
let page = ref(1);
const loader = ref(false);
let contacts = ref([])
let projects = ref([])
let allStatus = ref([
  {
    id: '',
    name: 'All'
  },
  {
    id: 'pending',
    name: 'Pending'
  },
  {
    id: 'approved',
    name: 'Approved'
  }
])
let supplier_id = ref(null)
let project_id = ref(null)
let po_number = ref('')
let cs_number = ref('')
let status = ref('')
let quick_search = ref('')

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  query += '&supplier_id=' + (supplier_id.value == null ? '' : supplier_id.value)
  query += '&project_id=' + (project_id.value == null ? '' : project_id.value)
  query += '&po_number=' + po_number.value
  query += '&cs_number=' + cs_number.value
  query += '&status=' + (status.value == null ? '' : status.value)
  if (page.value) query += '&page=' + page.value
  return query
}

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

async function getITList() {
  try {
    const res = await fetchImportTracker(getQuery());
    if (res.status) {
      ipList.value = res.data;
    } else {
      ipList.value = {};
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getITList();
  }, 100)
}

onMounted(async () => {
  loader.value = true
  const projectsRes = fetchProjects(getQuery());
  const companyQuery = `?company_id=${companyId.value}`
  const contactRes = fetchContactProfiles(companyQuery)
  await Promise.all([
    projectsRes.then(res => {
      if (res.data) projects.value = res.data;
    }),
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
  ]).then(() => {
    loader.value = false;
  }).catch((err) => {
    loader.value = false
  });

  await getITList();
})
</script>
