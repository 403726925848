<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-6">
                <label class="align-middle">Date</label>
                <input required type="date" class="form-control" v-model="formData.date">
              </div>
              <div class="col-6">
                <label class="form-label" for="number">Number</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="number"
                      v-model="formData.number"
                      class="form-control add-credit-card-mask"
                      placeholder="Number"
                  />
                  <ErrorMessage name="number" class="invalid-feedback d-block qcont"/>
                </div>
              </div>
              <div class="col-6">
                <label class="form-label" for="short_description">Short Description</label>
                <vField
                    as="textarea"
                    v-model="formData.short_description"
                    name="short_description"
                    id="short_description"
                    class="form-control add-credit-card-mask"
                    placeholder="Short Description"
                />
                <ErrorMessage name="short_description" class="invalid-feedback d-block qcont"/>
              </div>
              <div class="col-6">
                <label class="form-label" for="full_description">Full Description</label>
                <vField
                    as="textarea"
                    v-model="formData.full_description"
                    name="full_description"
                    id="full_description"
                    class="form-control add-credit-card-mask"
                    placeholder="Full Description"
                />
                <ErrorMessage name="full_description" class="invalid-feedback d-block qcont"/>
              </div>
              <div class="col-6">
                <label class="align-middle">Assign to</label>
                <vField v-model="formData.company_contact_id" class="d-none" name="company_contact_id"/>
                <v-select
                    placeholder="Select..."
                    v-model="formData.company_contact_id"
                    :options="contacts"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
              <div class="col-6">
                <label class="align-middle">Next Follow up date</label>
                <input type="date" class="form-control" v-model="formData.follow_up_date">
              </div>
              <div class="col-6">
                <label class="align-middle" for="linked_reference_no">Linked reference no</label>
                <div class="input-group">
                  <vField
                      id="linked_reference_no"
                      type="text"
                      name="linked_reference_no"
                      v-model="formData.linked_reference_no"
                      class="form-control add-credit-card-mask"
                      placeholder="Linked reference no"
                  />
                </div>
              </div>
              <div class="col-6">
                <label for="amount" class="align-middle">Amount</label>
                <div class="input-group">
                  <vField
                      id="amount"
                      type="text"
                      name="amount"
                      v-model="formData.amount"
                      class="form-control"
                      placeholder="Amount"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-2">
                  <label for="attachment" class="form-label fw-bold">Attachment</label>
                  <BlobFileViewer :attachments="formData.attachments"/>
                  <FileUpload
                      :is-drag-active="true"
                      button-text="Upload Documents"
                      v-model="formData.attachments"
                  >
                  </FileUpload>
                </div>
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                {{ isEdit ? 'Update' : 'Submit' }}
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";
import FileUpload from "@/components/atom/FileUpload";
import BlobFileViewer from "@/components/atom/BlobFileViewer";

const props = defineProps({
  contacts: {
    type: Array,
    default: []
  },
  formData: Object,
  modalTitle: {
    type: String,
    default: 'Add Tag Register'
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})

const $emit = defineEmits(['onSubmit', 'onClose'])

let isOpen = ref(false)
const loading = ref(false)
const schema = ref({})

const toggleModal = (isEdit = false) => {
  isOpen.value = !isOpen.value
  if (!isEdit) {
    $emit('onClose')
  }
}

const submit = () => {
  $emit("onSubmit")
}

defineExpose({toggleModal, loading})

</script>
